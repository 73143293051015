import React from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-8 logo">
              <Link to="/">
                {/* <img
                  src="../assets/img/Daily-Specials.jpg"
                  alt=""
                  className="img-thumbnail w-25"
                /> */}
              </Link>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-4 menu">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                  <button
                    className="navbar-toggler bg-light"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse justify-content-end"
                    id="navbarSupportedContent"
                  >
                    <ul className="nav navbar-nav">
                      <li className="nav-item dropdown">
                        <Link to="/" className="nav-link dropdown-toggle">
                          Home
                        </Link>
                        <ul className="dropdown-menu">
                          <li>
                            <Link to="/about" className="dropdown-item">
                              About
                            </Link>
                          </li>
                          <li>
                            <Link to="/theowner" className="dropdown-item">
                              The Owners
                            </Link>
                          </li>
                          <li>
                            <Link to="/privacypolicy" className="dropdown-item">
                              Privacy Policy
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <Link to="/menu" className="nav-link dropdown-toggle">
                          Menu
                        </Link>

                        <ul className="dropdown-menu">
                          <li>
                            <Link to="/dailyspecials" className="dropdown-item">
                              Daily Specials
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          to="/bookyourparty"
                          className="nav-link dropdown-toggle"
                        >
                          Book Your Party
                        </Link>
                        <ul className="dropdown-menu">
                          <li>
                            <Link to="/partyplatters" className="dropdown-item">
                              Party Platters
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <Link to="/event" className="nav-link dropdown-toggle">
                          Events
                        </Link>

                        <ul className="dropdown-menu">
                          <li>
                            <Link to="/videos" className="dropdown-item">
                              Happy Hour
                            </Link>
                          </li>
                          <li>
                            <Link to="/gallery" className="dropdown-item">
                              Gallery
                            </Link>
                          </li>
                          <li>
                            <Link to="/videos" className="dropdown-item">
                              Video
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link to="/testimonials" className="nav-link">
                          Testimonials
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/contact" className="nav-link">
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navigation;
