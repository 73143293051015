import React from "react";

const TheOwnerContent = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <h2 className="text-center p-5">The Owner</h2>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-lg-12 col-md-12">
            <p className="text-center">Proprietor – Tejinder Sandhu</p>
          </div>
        </div>
        <div className="row">
          <div className="col-2  d-sm-none"></div>
          <div className="col-lg-12 col-md-12">
            <p className="p-lg-5 p-sm-0">
              The Corinthian Lounge & Sports Bar, a Windsor Mill Community
              Staple. That’s why we’ve insisted on the finest seafood, the sea
              has to offer since we opened our first restaurant in 1976. Since
              then, lots of things have changed. But one thing hasn’t –our
              relentless pursuit of quality and, above all, freshness
            </p>
          </div>
        </div>
        <div className="row text-center mt-4 mb-4">
          <div className="col-lg-12 col-md-12">
            <img src="../assets/img/Daily-Specials.jpg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default TheOwnerContent;
