import React from "react";
import Navigation from "../../components/Navigation";
import ContactContent from "./ContactContent/ContactContent";
import Footer from "../../components/Footer";

const Contact = () => {
  return (
    <>
      <Navigation />
      <ContactContent />
      <Footer />
    </>
  );
};

export default Contact;
