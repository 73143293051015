import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import BookyourPartyContent from "./BookyourPartyContent/BookyourPartyContent";

const BookyourParty = () => {
  return (
    <>
      <Navigation />
      <BookyourPartyContent />
      <Footer />
    </>
  );
};

export default BookyourParty;
