import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <p>Privacy Policy</p>
            <p> Corinthian Lounge &copy; All rights reserved - 2023 </p>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-end">
            <p className="text-end">
              Design &amp; Developed by{" "}
              <span>
                <Link to={`http://www.sharptargetseo.com/`}>
                  SharpTargetSEO
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
