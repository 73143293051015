import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import MenuContent from "./MenuContent/MenuContent";

const Menu = () => {
  return (
    <>
      <Navigation />
      <MenuContent />
      <Footer />
    </>
  );
};

export default Menu;
