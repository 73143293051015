import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import TestimonialsContentTwo from "./TestimonialsContent/TestimonialsContentTwo";

const Testimonials = () => {
  return (
    <>
      <Navigation />
      {/* <TestimonialsContent /> */}
      <TestimonialsContentTwo />
      <Footer />
    </>
  );
};

export default Testimonials;
