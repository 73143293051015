import React from "react";

const GalleryContent = () => {
  return (
    <>
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-12 col-md-12">
            <h2 className="text-center p-5">Gallery</h2>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-12 col-md-12">
            <h4>Celebrities and Guest</h4>
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/264835_232363446794897_4659861_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/267871_232363196794922_7483855_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/282384_232363656794876_2506433_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/268490_232363296794912_7636009_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/379657_295204303844144_537105992_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/390059_295204567177451_1052615223_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img src="../assets/img/CL3.jpg" alt="" className="img-thumbnail" />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/IMG_1096.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/IMG_1127.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/IMG_1615.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/IMG_8415-1024x768.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/IMG_8411_1-1024x779.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/IMG_8406_1-940x1024.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/IMG_8407_1-1024x1012.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/IMG_8408_1-943x1024.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/IMG_8409_1-943x1024.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/IMG_8410_1-913x1024.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>

          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/Patti-853x1024.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-12 col-md-12 mb-4">
            <h4>Great Food & Drinks</h4>
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/7907_590161737681731_1384999190_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/164367_181593645205211_1209431_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/166361_181593718538537_5559760_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/167189_181593601871882_7688031_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/167828_182633901767852_4762730_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1374816_918815084816393_3131345920889014851_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>

          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/992873_598832956814609_1120686312_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1236070_636598663038038_1817168282_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1375065_655852767779294_997397569_n-1.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1375065_655852767779294_997397569_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1377967_655818617782709_651821920_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1379691_652944964736741_1274581752_n-1.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>

          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1379691_652944964736741_1274581752_n-1.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1380478_652915131406391_1713540596_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1382197_646465395384698_2119535731_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1385872_655814304449807_1217251675_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1393614_655825121115392_2006862962_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1457632_682680448429859_1084757514_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>

          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1911789_809186132445956_8682197596563907685_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/imagejpeg_20.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_8ozCrabCake-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>

          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_BuffaloWings-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_Cheeseburger-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_ChefSalad-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_ChickenandShrimpCaesar-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_CrabPretzel-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_CreamOfCrabSoup-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>

          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_FriedSeafoodPlatter-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_JerkBakedWings-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_JumboLumpImperialCrabCakePotato-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_PepperoniPizza-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_Reuben-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_SeafoodGreekSalad-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>

          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_SeafoodQuesadilla-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_SnowCrabLegs-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_SteamerBowl-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/CorinthianLounge_TurkeyClub-1024x683.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1426498_679456108752293_1914728197_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1456002_682556861775551_252703222_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/1461109_673740425990528_1788530554_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/216102_528051710559401_1477765474_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-2 mb-3">
            <img
              src="../assets/img/553998_441075069257066_115634274_n.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>

          <div className="col-lg-12 col-md-12">
            <h4>Our Venue</h4>
          </div>

          <div className="col-lg-3 mb-3">
            <img
              src="../assets/img/IMG_1089.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>

          <div className="col-lg-3 mb-3">
            <img
              src="../assets/img/IMG_20150104_094410-1024x768.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <img
              src="../assets/img/IMG_20150104_094431-1024x768.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <img
              src="../assets/img/IMG_20150104_094506-1024x768.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <img
              src="../assets/img/IMG_20150104_094525-1-1024x768.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <img
              src="../assets/img/IMG_20150104_094539-1024x768.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <img
              src="../assets/img/IMG_20150104_094554-1024x768.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <img
              src="../assets/img/Patio3.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <img
              src="../assets/img/Patio1.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>

          <div className="col-lg-3 mb-3">
            <img
              src="../assets/img/Patio4.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <img
              src="../assets/img/imagejpeg_3.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <img
              src="../assets/img/Patio5.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <img
              src="../assets/img/Patio6.jpg"
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-3 mb-3">
            <img src="../assets/img/CL.jpg" alt="" className="img-thumbnail" />
          </div>
          <div className="col-lg-3 mb-3">
            <img src="../assets/img/CL1.jpg" alt="" className="img-thumbnail" />
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryContent;
