import React from "react";
import Navigation from "../../components/Navigation";
import AboutArea from "./AboutArea/AboutArea";
import LatestPost from "./LatestPost/LatestPost";
import Footer from "../../components/Footer";

const Home = () => {
  return (
    <>
      <div className="home-header">
        <Navigation />
        <AboutArea />
      </div>
      <LatestPost />
      <Footer />
    </>
  );
};

export default Home;
