import React from "react";
import { Link } from "react-router-dom";

const VideosContent = () => {
  return (
    <>
      <div className="container">
        <div className="row mb-4">
          <div className="col-12">
            <h2 className="text-center p-5">Happy Hour - Corinthian Lounge</h2>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <h2 className="text-center p-5">Corinthian’s Video Clips</h2>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-6 text-center">
            <Link to="https://vimeo.com/205713327">
              <img
                src="../assets/img/1612.jpg"
                alt="Video"
                className="img-thumbnail rounded-5"
              />
            </Link>
            <h4 className="mt-3">
              <Link to="https://vimeo.com/205713327">
                Thursday Night Jazz at Corinthians_1612
              </Link>
            </h4>
          </div>
          <div className="col-6 text-center">
            <Link to="https://vimeo.com/205713401">
              <img
                src="../assets/img/1618.jpg"
                alt="Video"
                className="img-thumbnail rounded-5"
              />
            </Link>
            <h4 className="mt-3">
              <Link to="https://vimeo.com/205713401">
                Thursday Night Jazz at Corinthians_1618
              </Link>
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideosContent;
