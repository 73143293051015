import React from "react";
import { Link } from "react-router-dom";

const BookyourPartyContent = () => {
  return (
    <div className="about-content">
      <div className="container">
        <div className="pb-5 row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h2 className="text-center p-5">BOOK YOUR PARTY</h2>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 pt-3">
            <img
              className="mb-5 d-block mx-auto h-50 w-auto"
              src="../assets/img/BookYourParty.jpg"
              alt=""
            />

            <p className="pt-5 text-center">
              Let us help you celebrate that special occasion. Book your Party
              Today! Please call us.
            </p>
            <p className="pt-3 text-center">
              <Link to="tel:4103657078">(410) 265-7078 </Link> between 8 am and
              1:30am, Monday to Sunday.
            </p>
            <p className="pt-3 mb-5 text-center">Book Your Party Today</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookyourPartyContent;
