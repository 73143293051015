import React from "react";
import Navigation from "../../components/Navigation";
import DailySpecialsContent from "./DailySpecialsContent/DailySpecialsContent";
import Footer from "../../components/Footer";

const DailySpecials = () => {
  return (
    <>
      <Navigation />
      <DailySpecialsContent />
      <Footer />
    </>
  );
};

export default DailySpecials;
