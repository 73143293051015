import React from "react";
import Navigation from "../../components/Navigation";
import EventContent from "./EventContent/EventContent";
import Footer from "../../components/Footer";

const Event = () => {
  return (
    <>
      <Navigation />
      <EventContent />
      <Footer />
    </>
  );
};

export default Event;
