import React from "react";

const PartyPlattersContent = () => {
  return (
    <>
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-12 col-md-12">
            <h2 className="text-center p-5">Party Platters</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <h6>Assorted Platter</h6>
            <p>(Feeds 10-12 People)</p>
            <p>32 Wings</p>
            <p>20 Meatballs</p>
            <p>Double Order Potato Skins</p>
            <p>Cole Slaw or Potato Salad</p>
          </div>
          <div className="col-3 pt-5">
            <h6>$74.00</h6>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-9">
            <h6>Large Party Garden Salad</h6>
            <p>(Choice of Dressings)</p>
          </div>
          <div className="col-3 pt-2 ">
            <h6>$19.95</h6>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-9">
            <h6>Large String Bean</h6>
          </div>
          <div className="col-3 ">
            <h6>$22.00</h6>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-9">
            <h6>5 lbs. Potato Salad</h6>
          </div>
          <div className="col-3 ">
            <h6>$19.95</h6>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-9">
            <h6>5 lbs. Pasta Salad</h6>
          </div>
          <div className="col-3 ">
            <h6>$19.95</h6>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-9">
            <h6>Veggie Tray</h6>
          </div>
          <div className="col-3 ">
            <h6>$45.00</h6>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-9">
            <h6>Cheese Tray</h6>
          </div>
          <div className="col-3 ">
            <h6>$59.00</h6>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-9">
            <h6>25 Meatballs</h6>
          </div>
          <div className="col-3 ">
            <h6>$24.00</h6>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-9">
            <h6>25 Chicken Tenders</h6>
          </div>
          <div className="col-3 ">
            <h6>$49.00</h6>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-9">
            <h6>20 Catfish Fingers </h6>
          </div>
          <div className="col-3 ">
            <h6>$45.00</h6>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-9">
            <h6>20 Fried Shrimp (16 to 20 Count)</h6>
          </div>
          <div className="col-3 ">
            <h6>$45.00</h6>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-9">
            <h6>25 Backfin /Lump Crab Balls</h6>
            <p>(Fried or Broiled)</p>
          </div>
          <div className="col-3 ">
            <h6>$81.25</h6>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-9">
            <h6>25 Jumbo Lump Crab Balls</h6>
            <p>(Fried or Broiled)</p>
          </div>
          <div className="col-3 ">
            <h6>$123.75</h6>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <h6>Tax & Gratuity not included in price</h6>
            <h6>Condiments included with Party Platters</h6>
          </div>
        </div>
        <hr />
      </div>
    </>
  );
};

export default PartyPlattersContent;
