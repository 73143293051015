import React from "react";

const LatestPost = () => {
  return (
    <div className="letestpost">
      <div className="container mt-100 mb-100">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h2>LATEST POSTS</h2>
            <div className="letestpost-p">
              <p>by admin</p> /<p>January 23, 2023</p> /
              <p>Comments are Disabled</p>
            </div>
            <div className="letestpost-img">
              <img src="../assets/img/fb-icon.png" alt="" />
            </div>
            <p className="letestpost-follow">Follow Us on Facebook</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestPost;
