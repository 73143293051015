import React from "react";
import { Link } from "react-router-dom";

const EventContent = () => {
  return (
    <>
      <div className="container">
        <div className="row mb-4">
          <div className="col-12">
            <h2 className="text-center p-5">Upcoming Events</h2>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="card">
              <img
                src="../assets/img/DJ-1.jpg"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title">DJ Night at Corinthian</h5>
              </div>
              <div className="card-body">
                <p className="card-text">Tuesday, Thursday and Saturdays</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="card">
              <img
                src="../assets/img/Football-1024x683.jpg"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title">
                  Football Game Specials at Corinthian
                </h5>
              </div>
              <div className="card-body">
                <p className="card-text">
                  Join us during the football session for all televised games.
                </p>
                <h6>We have specials starting at $5.99</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="card">
              <img
                src="../assets/img/Whats-Happening.jpg"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title">What’s New at Corinthian</h5>
              </div>
              <div className="card-body">
                <p>
                  <Link to="#">Listen </Link>
                  to What’s Happening at Corinthian
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div className="card">
              <img
                src="../assets/img/95.9-300x169.png"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title">95.9 Radio Commercial</h5>
              </div>
              <div className="card-body">
                <p>
                  <Link to="#">Listen </Link>
                  to our 95.9 Radio Commercial
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventContent;
