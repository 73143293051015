import React from "react";
import Navigation from "../../components/Navigation";
import AboutContent from "./AboutContent/AboutContent";
import Footer from "../../components/Footer";

const About = () => {
  return (
    <>
      <Navigation />
      <AboutContent />
      <Footer />
    </>
  );
};

export default About;
