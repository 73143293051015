import React from "react";
import { Link } from "react-router-dom";

const AboutContent = () => {
  return (
    <div className="about-content">
      <div className="container">
        <div className="pb-5 row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h2 className="text-center p-5">ABOUT</h2>
          </div>
          <div className="pt-4 col-lg-12 col-md-12 col-sm-12">
            <p>
              Corinthian Restaurant and Lounge is the place for fine seafood in
              Windsor Mill Maryland. The Corinthian Restaurant and Lounge is an
              establishment that serves prepared food and beverages to order, to
              be consumed on and off of the premises. The term covers a
              multiplicity of venues, especially our world famous crabs, crab
              cakes, raw bar, and a diversity of styles of cuisine. That’s why
              we’ve insisted on the finest seafood the sea has to offer since we
              opened our first restaurant in 1976. Since then, lots of things
              have changed. But one thing hasn’t – our relentless pursuit of
              quality and, above all, freshness.
            </p>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 pt-3">
            <Link to="#">
              Recognized as #5 of the 11 BEST Restaurants to Eat Crabs in the
              Baltimore Area
            </Link>
            <p>
              Please familiarize yourself with{" "}
              <span>
                <Link to="#">menu</Link>
              </span>{" "}
              and visit our{" "}
              <span>
                <Link to="#">photo gallery</Link>
              </span>
            </p>
            <p className="pt-3 text-center">
              Come to Corinthian Restaurant Lounge and experience our quality
              food and service
            </p>
            <h5 className="pt-3 text-center">Our address:</h5>
            <p className="pt-3 text-center">
              7107 Windsor Mill Road, Baltimore, 21244
            </p>
            <h5 className="pt-3 text-center">Our Hours:</h5>
            <p className="pt-3 text-center">
              Bar is open Mon-Thurs: 12.00 noon to 1.00 a.m. Fri & Sat:
              11:00a.m. to 2:00 a.m. Sun: 11:00 a.m. to 1:00 a.m.
            </p>
            <p className="pt-3 text-center">
              Package Goods is open Sun-Thurs: 9.00 a.m. to 1.00 a.m. Fri & Sat:
              9:00 a.m. to 2:00 a.m.
            </p>
            <p className="pt-3 text-center">
              Kitchen is open Mon-Wed: 12.00 noon to 11.00 p.m. Thurs-Sat:
              11:00a.m. to 1:00 a.m. Sun: 11:00 a.m. to 11:00 p.m.
            </p>
            <h5 className="pt-3 text-center">Kitchen is Closed on Christmas</h5>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img
              className="d-block mx-auto w-50"
              src="../assets/img/about2.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
