import React from "react";
import { NavLink } from "react-router-dom";

const AboutArea = () => {
  return (
    <>
      <div className="slider">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2>Corinthian Lounge</h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
              <NavLink to="/about" className="btn btn-lg btn-outline-light">
                More About Us
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="arrow-bottom-slider">
        <img src="../assets/img/bottom-slider.png" alt="Slide Down" />
      </div>
    </>
  );
};

export default AboutArea;
