import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import TheOwnerContent from "./TheOwnerContent/TheOwnerContent";

const TheOwner = () => {
  return (
    <>
      <Navigation />
      <TheOwnerContent />
      <Footer />
    </>
  );
};

export default TheOwner;
