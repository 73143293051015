import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import BookyourParty from "./pages/BookyourParty/BookyourParty";
import TheOwner from "./pages/TheOwner/TheOwner";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Menu from "./pages/Menu/Menu";
import DailySpecials from "./pages/DailySpecials/DailySpecials";
import PartyPlatters from "./pages/PartyPlatters/PartyPlatters";
import Event from "./pages/Event/Event";
import Videos from "./pages/Videos/Videos";
import Gallery from "./pages/Gallery/Gallery";
import Testimonials from "./pages/Testimonials/Testimonials";
import Contact from "./pages/Contact/Contact";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/bookyourparty" element={<BookyourParty />} />
          <Route path="/theowner" element={<TheOwner />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/dailyspecials" element={<DailySpecials />} />
          <Route path="/partyplatters" element={<PartyPlatters />} />
          <Route path="/event" element={<Event />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
