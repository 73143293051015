import React from "react";

const DailySpecialsContent = () => {
  return (
    <>
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-12 col-md-12">
            <h2 className="text-center p-5">Daily Specials</h2>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-lg-12 col-md-12">
            <img src="../assets/img/Daily-Specials.jpg" alt="Daily Specials" />
          </div>
          <div className="col-lg-12 col-md-12 mt-4">
            <h5>Starting at $5.99</h5>
          </div>
          <div className="col-lg-12 col-md-12 mt-4">
            <h5>To find our about Today’s Specials</h5>
          </div>
          <div className="col-lg-12 col-md-12 mt-4 mb-4">
            <h5>Call 410-265-7078</h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default DailySpecialsContent;
