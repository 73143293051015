import React from "react";
import { Link } from "react-router-dom";

const TestimonialsContentTwo = () => {
  return (
    <>
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-12">
            <h2 className="text-center p-5">Testimonials</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 border border-2  mb-25 p-3 rounded-3 position-relative">
            <p>April 2017 </p>
            <h3>Lisa Wiggins</h3>
            <p>
              I celebrated my 51st birthday with family/Friends on April 28,
              2017. Ms. Tee did a great job with helping me set up my table for
              my guest. The food was great and the waitress Day was wonderful.
              The music was great; I danced all night with friends/family.
              Thanks Ms. Tee
            </p>
            <div className="tp-testimonial-two-qoute-custom">
              <i>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
                </svg>
              </i>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 border border-2  mb-25 p-3 rounded-3 position-relative">
            <p>February 2017 </p>
            <h3>Sarah Howell</h3>
            <p>
              I am sending you this note to thank several of your staff members.
              I brought a group of friends to the show on Thursday and we had
              the best time in your establishment. Our waitress, “Ms. Day” was a
              breath of fresh air as she waited on us with food and drinks.
              Secondly, we met your manager, Mr. William Hutchins and he was the
              best ever. We had a minor food issue and he had it corrected
              almost immediately with your kitchen staff. The crab cakes and
              king crab legs were excellent!
            </p>
            <p>
              We traveled from Silver Spring after being invited by one of our
              friends in the band. We had never been to the Corinthian but
              because of the service received by “Ms. Day” and attentiveness
              given by Mr. Hutchins, we will become regular Thursday night
              attendees. He seems to have the pulse of his clientele wrapped
              around his sleeve. He talked on the mike and had special kudos to
              a group called The Parking Lot Crew, who were sitting next to us!
            </p>
            <p>Great group of guys and ladies who also welcomed us!</p>
            <p>
              Feel free to post my comments on your website page, which is so
              professionally done. Please see that Ms. Day and Mr. Hutchins are
              made aware of our gratitude. I asked him for your business card
              and email but he did not give me his information. We are glad to
              have a place where we as a collective, can enjoy live
              entertainment without the hassle.
            </p>
            <p>Sincerely,</p>
            <p>Sarah Howell</p>
            <div className="tp-testimonial-two-qoute-custom">
              <i>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
                </svg>
              </i>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 border border-2  mb-25 p-3 rounded-3 position-relative">
            <p>July 2016 </p>
            <h3>Regina T</h3>
            <p>
              My granddaughter and I shared two jumbo lump crab cakes for lunch
              and there wasn’t a crumb left. The seasoning was perfect. I topped
              it off with what tasted like homemade tartar sauce which added
              even more flavor. This was one of the best crab cakes I’ve had in
              a while. Treat yourself to a crab cake…you wont be sorry.
            </p>
            <div className="tp-testimonial-two-qoute-custom">
              <i>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
                </svg>
              </i>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 border border-2  mb-25 p-3 rounded-3 position-relative">
            <p>June 2016 </p>
            <h3>Will Featherstone</h3>
            <p>
              Recognized as number five of the{" "}
              <Link to="https://web.archive.org/web/20180814210352/http://www.featherstoneco.com/blog/The-11-Best-Restaurants-to-Eat-Crabs-in-the-Baltimore-Area/15575">
                {" "}
                11 BEST Restaurants to Eat Crabs in the Baltimore Area
              </Link>
            </p>
            <div className="tp-testimonial-two-qoute-custom">
              <i>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
                </svg>
              </i>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 border border-2  mb-25 p-3 rounded-3 position-relative">
            <p>May 2016 </p>
            <h3>Rhonda</h3>
            <p>
              Was trying something new and I must say the experience was nice
              along with the food being good. The staff is great. I would say
              its the best Happy Hour out this way .
            </p>
            <div className="tp-testimonial-two-qoute-custom">
              <i>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
                </svg>
              </i>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 border border-2  mb-25 p-3 rounded-3 position-relative">
            <p>March 2015 </p>
            <h3>William Hutchins</h3>
            <p>
              We had a corporate luncheon at The Corinthian and all my invitees
              really enjoyed themselves. The crab cake sub was the favorite
              choice. Virginia and New York attendees said it was the best crab
              cake they had ever had! Thanks to the owners and staff for a great
              afternoon.
            </p>
            <div className="tp-testimonial-two-qoute-custom">
              <i>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
                </svg>
              </i>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 border border-2  mb-25 p-3 rounded-3 position-relative">
            <p>March 2015 </p>
            <h3>Kathy</h3>
            <p>
              Fabulous experience!! And the food…..Over the top!!! We loved the
              crab cakes. We will be back!!
            </p>
            <div className="tp-testimonial-two-qoute-custom">
              <i>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
                </svg>
              </i>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 border border-2  mb-25 p-3 rounded-3 position-relative">
            <p>March 2015 </p>
            <h3>Kathy</h3>
            <p>
              Fabulous experience!! And the food…..Over the top!!! We loved the
              crab cakes. We will be back!!
            </p>
            <div className="tp-testimonial-two-qoute-custom">
              <i>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
                </svg>
              </i>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 border border-2  mb-25 p-3 rounded-3 position-relative">
            <p>January 2015 </p>
            <h3>William</h3>
            <p>Corinthian Restaurant & Lounge has the best seafood in town!</p>
            <div className="tp-testimonial-two-qoute-custom">
              <i>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
                </svg>
              </i>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 border border-2  mb-25 p-3 rounded-3 position-relative">
            <p>August 2014 </p>
            <h3>Anonymous</h3>
            <p>
              {" "}
              contacted Corinthian Restaurant &amp; Lounge for help with
              organizing my husband’s birthday party. The food was delicious and
              all the guests were pleased with the food and the service. Thank
              you so much!
            </p>
            <div className="tp-testimonial-two-qoute-custom">
              <i>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
                </svg>
              </i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialsContentTwo;
