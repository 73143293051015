import React from "react";

const MenuContent = () => {
  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-lg-12 col-md-12">
          <h2 className="text-center p-5">Menu</h2>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-lg-6 col-md-6">
          <img
            className="img-thumbnail"
            src="../assets/img/menu-1.jpg"
            alt="Menu"
          />
        </div>
        <div className="col-lg-6 col-md-6">
          <img
            className="img-thumbnail"
            src="../assets/img/menu-2.jpg"
            alt="Menu"
          />
        </div>
      </div>
    </div>
  );
};

export default MenuContent;
