import React, { useState } from "react";

import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelopeOpen } from "react-icons/fa";
import { Link } from "react-router-dom";

const ContactContent = () => {
  const [formStatus, setFormStatus] = useState("Send Message");
  const onSubmit = (e) => {
    e.preventDefault();
    setFormStatus("Submitting...");
    const { name, email, phone, message } = e.target.elements;
    let conFom = {
      name: name.value,
      email: email.value,
      phone: phone.value,
      message: message.value,
    };
    console.log(conFom);
  };
  return (
    <>
      <div className="container mt-5 ">
        <div className="row mb-4">
          <div className="col-lg-12 col-md-12">
            <h2 className="text-center p-5">Contact Us</h2>
          </div>
        </div>
        <section className="tp-contact-area pb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div
                  className="section-title-wrapper-two mb-50 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">
                    Get Free Estimate
                  </h5>
                  <h2 className="tp-section-title heading-color-black">
                    If you Have Any Query, Don’t Hesitate <br />
                    Contact with us{" "}
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="row custom-mar-20">
                  <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                    <div
                      className="tp-contact-info mb-40 wow fadeInUp"
                      data-wow-delay=".4s"
                    >
                      <div className="tp-contact-info-icon">
                        <i>
                          {" "}
                          <FaMapMarkerAlt className="contact_icon" />{" "}
                        </i>
                      </div>
                      <div className="tp-contact-info-text">
                        <h4 className="tp-contact-info-title mb-15">Address</h4>
                        <p>
                          Corinthian Restaurant and Lounge <br />
                          7107 Windsor Mill Road, <br />
                          Baltimore, Maryland 21244
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                    <div
                      className="tp-contact-info mb-40 wow fadeInUp"
                      data-wow-delay=".6s"
                    >
                      <div className="tp-contact-info-icon">
                        <i>
                          {" "}
                          <FaPhoneAlt className="contact_icon" />{" "}
                        </i>
                      </div>
                      <div className="tp-contact-info-text">
                        <h4 className="tp-contact-info-title mb-15">Phone</h4>
                        <Link to="tel:4102657078">(410)-265-7078</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                    <div
                      className="tp-contact-info mb-40 wow fadeInUp"
                      data-wow-delay=".8s"
                    >
                      <div className="tp-contact-info-icon">
                        <i>
                          {" "}
                          <FaEnvelopeOpen className="contact_icon" />{" "}
                        </i>
                      </div>
                      <div className="tp-contact-info-text">
                        <h4 className="tp-contact-info-title mb-15">Email</h4>
                        <Link to="mailto:info@corinthianlounge.com">
                          info@corinthianlounge.com
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="tp-contact-form">
                  <form onSubmit={onSubmit}>
                    <div className="row custom-mar-20">
                      <div className="col-md-12 custom-pad-20">
                        <div className="tp-contact-form-field mb-20">
                          <input
                            type="text"
                            name="name"
                            placeholder="Full name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 custom-pad-20">
                        <div className="tp-contact-form-field mb-20">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email Address"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 custom-pad-20">
                        <div className="tp-contact-form-field mb-20">
                          <input type="text" name="phone" placeholder="Phone" />
                        </div>
                      </div>
                      <div className="col-md-12 custom-pad-20">
                        <div className="tp-contact-form-field mb-20">
                          <textarea
                            placeholder="Your Message"
                            name="message"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-12 custom-pad-20">
                        <div className="tp-contact-form-field">
                          <button
                            type="submit"
                            className="theme-btn text-white"
                          >
                            <i className="flaticon-enter"></i> {formStatus}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactContent;
