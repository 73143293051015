import React from "react";
import Navigation from "../../components/Navigation";
import GalleryContent from "./GalleryContent/GalleryContent";
import Footer from "../../components/Footer";

const Gallery = () => {
  return (
    <>
      <Navigation />
      <GalleryContent />
      <Footer />
    </>
  );
};

export default Gallery;
